import React from 'react';
import './styles.css';

const AppPlaceholder = () => (
  <div className="ph-container">
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    <div className="ph-text">Loading...</div>
  </div>
);

export default AppPlaceholder;
