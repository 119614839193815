import React from 'react';
import { render } from 'react-dom';
import AppPlaceholder from 'app/Placeholder';
import { QueryClient, QueryClientProvider } from 'react-query';

const App = React.lazy(() => import('app'));
const queryClient = new QueryClient();

const Root = () => (
  <React.Suspense fallback={<AppPlaceholder />}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.Suspense>
);

render(<Root />, document.getElementById('app'));
